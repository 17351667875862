<template>
    <div :class="sort">
        <div class="flex flex-h-center">
            <div class="center padding"
                style="background-color: #f0f0f0; border-radius: 20px;width: 360px;position: relative;">
                <h3>Audio Player</h3>

                <div class="margin-t st1 center" v-html="timer > 0 ? 'Beginning in ' + timer : '&nbsp;'">
                </div>

                <!-- <div @click="skip()" class="timer st ul pointer" v-if="timer > 0">跳过({{ timer }}s)</div> -->
                <div class="flex flex-between flex-v-center time">
                    <div @mousedown="startChange" @mouseup="endChange" class="flex1 margin-l">
                        <el-slider :step="1" @change="changeTime" v-model="time" :max="duration"></el-slider>
                    </div>
                    <div class="margin-x st1">{{ format_time(time) }}</div>
                </div>
                <div class="flex flex-v-center margin-t">
                    <div class="flex flex1">
                        <!-- <el-image @click="start()" class="icon-player pointer"
                            :src="isPlaying ? '/web/image/' + sort + '/pause.png' : '/web/image/' + sort + '/play.png'"
                            fit="contain"></el-image> -->
                        <el-image @click="rerun()" class="pointer icon-player" :src="'/web/image/' + sort + '/rerun.png'"
                            fit="contain"></el-image>
                        <!-- <el-tooltip placement="right" effect="light" :visible-arrow="false"
                            popper-class="volume-tooltip">
                            <div slot="content">
                                <el-slider :show-tooltip="false" class="volume" style="width:100px" v-model="volume"
                                    :min="0" :step="0.1" :max="1" @change="updateVolume">
                                </el-slider>
                            </div>
                            <div style="display:inline-block;position: relative;" :class="[{ 'mute': volume == 0 },sort]">
                                <el-image @click="switchVolume" class="pointer icon-player"
                                    :src="'/web/image/' + sort + '/voice.png'" fit="contain"></el-image>
                            </div>
                        </el-tooltip> -->
                    </div>
                    <div class="flex1 flex-h-center">
                        <el-image @click="start()" class="icon-player pointer"
                            :src="isPlaying ? '/web/image/' + sort + '/pause.png' : '/web/image/' + sort + (timer > 0 ? '/audio_start1.png' : '/play.png')"
                            fit="contain"></el-image>
                    </div>

                    <div class="flex1 flex  flex-h-end">
                        <el-dropdown class="margin-x" @command="updateRate">
                            <span class="el-dropdown-link">
                                {{ playbackRate }}x<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :class="sort" command="0.5">0.5x</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="0.8">0.8x</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="1.0">1.0x</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="1.2">1.2x</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="1.5">1.5x</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown @command="updateVoice">
                            <span class="el-dropdown-link">
                                {{ $t($store.getters.getVoiceLabel(lang)) }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu v-if="lang == 'zh'" slot="dropdown">
                                <el-dropdown-item :class="sort" command="Origin,原声">{{$t('原声')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Andy,中语男音">{{$t('中语男音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Abby,中语女音">{{$t('中语女音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Luca,粤语男音">{{$t('粤语男音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Luna,粤语女音">{{$t('粤语女音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Random,随机">{{$t('随机')}}</el-dropdown-item>
                            </el-dropdown-menu>


                            <el-dropdown-menu v-else slot="dropdown">
                                <el-dropdown-item :class="sort" command="Origin,原声">{{$t('原声')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Andy,美式男音">{{$t('美式男音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Abby,美式女音">{{$t('美式女音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Luca,英式男音">{{$t('英式男音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Luna,英式女音">{{$t('英式女音')}}</el-dropdown-item>
                                <el-dropdown-item :class="sort" command="Random,随机">{{$t('随机')}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>

            </div>
        </div>

        <audio ref="audio" preload="metadata" style="display:none" :src="src" @play="onPlay" @pause="onPause"
            @ended="onEnded" @timeupdate="onTimeUpdate" @loadedmetadata="onLoadedmetadata">
            浏览器太老咯，请升级浏览器吧~
        </audio>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "AudioPlayer",

    data() {
        return {
            timer: 0,
            time: 0,
            isPlaying: false,
            // voice: "Origin",
            voice1: "",
            // voice_label: "原声",
            duration: 0,
            currentTime: 0,
            playbackRate: '1.0',
            volume: 0.5,
            src: "",
            timing: false,
            changeTiming: false,
            lastVolume: 0
        };
    },

    computed: {
        voice() {
            return this.$store.getters.getVoice(this.lang);
        }
    },

    watch: {
        voice() {
            this.updateAudioList();
        },
        no(val) {
            if (val) {
                this.updateAudioList();
            }
        }
    },

    props: {
        no: {
            type: String,
            default: "",
        },

        sort: {
            type: String,
            default: "Speaking",
        },

        time_limit: {
            type: Number,
            default: 0,
        },

        lang: {
            type: String,
            default: "en",
        },
    },
    created() {
        if (this.no) {
            this.updateAudioList();
        }

        this.$bus.$on("stop-player", () => {
            this.timer = -99;
            this.$nextTick(() => {
                this.stop();
            })
        })
        this.playbackRate = localStorage.getItem("playbackRate") || 1;
    },
    methods: {
        switchVolume() {
            if (this.volume > 0) {
                this.lastVolume = this.volume;
                this.volume = 0;
            } else {
                this.volume = this.lastVolume;
                this.lastVolume = 0;
            }
            this.updateVolume();
        },

        startChange() {
            this.changeTiming = true;
        },

        endChange() {
            this.changeTiming = false;
        },

        skip() {
            this.timer = 0;
        },

        format_time(time) {
            if (time < 60) {
                return "00:" + (time > 9 ? time : ("0" + time));
            } else {
                let m = parseInt(time / 60);
                let s = time % 60;
                return (m > 9 ? m : '0' + m) + ":" + (s > 9 ? s : '0' + s)
            }
        },



        start_timer() {
            setTimeout(() => {
                this.timer--;
                if (this.timer > 0) {
                    this.timing = true;
                    this.start_timer()
                } else {
                    this.timing = false;
                    if (this.timer <= -99) {
                        return;
                    }
                    this.play();
                }
            }, 1000);
        },

        stop() {
            this.time = 0;
            this.$refs.audio.pause();
            this.$refs.audio.currentTime = 0;
        },


        play(timer) {
            if (timer != undefined) this.timer = timer;
            if (this.timer > 0) {
                if (!this.timing) {
                    this.start_timer()
                }
            } else {
                if (this.$refs.audio) {
                    this.$refs.audio.play();
                    this.$emit("play");
                }
            }
        },

        rerun() {
            this.$refs.audio.load();
            this.time = 0;
            this.$refs.audio.currentTime = 0;
            this.$nextTick(() => {
                this.$refs.audio.play();
            })
        },


        changeTime() {
            this.$refs.audio.currentTime = this.time
            this.$nextTick(() => {
                this.changeTiming = false;
            })
        },

        onTimeUpdate() {
            if (this.isPlaying && !this.changeTiming) {
                this.time = Math.ceil(this.$refs.audio.currentTime);
            }
        },

        onLoadedmetadata() {
            if (this.duration > 0) return;
            this.$nextTick(() => {
                let voice = this.voice1 ? this.voice1 : this.$store.getters.getVoice(this.lang);
                if (this.$refs.audio.duration == window.Infinity && this.duration == 0) {
                    this.$http.post("/api/audio/duration/" + this.no + "/" + voice).then((duration) => {
                        if (duration == 0) {
                            setTimeout(() => {
                                this.onLoadedmetadata();
                            }, 1000);
                        } else {
                            this.duration = Math.ceil(duration);
                        }
                    })
                } else {
                    this.duration = Math.ceil(this.$refs.audio.duration);
                }
            })

        },


        updateVolume() {
            this.$refs.audio.volume = this.volume
        },

        updateRate(rate) {
            this.playbackRate = rate;
            localStorage.setItem("playbackRate", rate);
            this.$refs.audio.playbackRate = this.playbackRate;
        },

        updateVoice(voice) {
            localStorage.setItem(this.lang + "_audio_voice", voice);

            if (this.lang == "zh") {
                this.$store.commit("setZhVoice", voice);
            } else {
                this.$store.commit("setEnVoice", voice);
            }

            // let data = voice.split(",");
            // this.voice = data[0];
            // this.voice_label = data[1];
            this.updateAudioList();
        },
        getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        updateAudioList() {
            if (this.$store.getters.getVoice(this.lang) == "Random") {
                this.voice1 = ["Andy", "Abby", "Luca", "Luna"][this.getRndInteger(0, 3)];
            } else {
                this.voice1 = "";
            }
            this.$nextTick(() => {
                this.$refs.audio.pause();
                this.$refs.audio.currentTime = 0;
                this.$refs.audio.playbackRate = this.playbackRate;
                this.time = 0;
                this.duration = 0;
                this.isPlaying = false;
                this.src = this.$host + "/api/audio/" + this.no + "/" + (this.voice1 ? this.voice1 : this.$store.getters.getVoice(this.lang)) + "?v=" + moment().format('MMDDHHmm');
            })
        },
        start() {
            if (this.isPlaying) {
                this.$refs.audio.pause();
            } else {
                this.play(0);
            }
        },
        onPlay() {
            this.timer = 0;
            this.isPlaying = true;
            this.$nextTick(() => {
                this.$refs.audio.playbackRate = this.playbackRate;
            })
        },
        onPause() {
            this.isPlaying = false;
        },
        onEnded() {
            this.$refs.audio.load();
            this.$emit("ended");
        },
    },
};
</script>
<style scoped>
.timer {
    position: absolute;
    right: 25px;
    top: 20px;
}

.time>>>.el-slider__runway {
    background-color: #ddd;
}


.volume>>>.el-slider__runway {
    background-color: #ddd;
}

.volume>>>.el-slider__button,
.time>>>.el-slider__button {
    border-color: #E6A23C;
}

.time>>>.el-slider__bar,
.volume>>>.el-slider__bar {
    background-color: #E6A23C;
}

.el-dropdown-link {
    font-size: 14px;
    cursor: pointer;
    color: #E6A23C;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.mute::after {
    position: absolute;
    left: 0;
    top: 15px;
    content: ' ';
    height: 3px;
    width: 30px;
    transform: rotate(45deg);
}

.mute.Listening::after {
    background-color: #86a6c5;
}

.mute.Reading::after {
    background-color: #d5795d;
}

.mute.Writing::after {
    background-color: #796f85;
}

.mute.Speaking::after {
    background-color: #de7d89;
}

.Speaking>>>.el-button--primary {
    background-color: #86a6c5;
    border-color: #86a6c5;
}

.Listening>>>.el-slider__button {
    border-color: #86a6c5;
}

.Listening>>>.el-dropdown-link {
    color: #86a6c5;
}


.Listening>>>.el-button--primary {
    background-color: #de7d89;
    border-color: #de7d89;
}

.Speaking>>>.el-slider__button {
    border-color: #de7d89;
}

.Speaking>>>.el-dropdown-link {
    color: #de7d89;
}
</style>
